.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 20%;
    border-radius: 50%;
    transition: all ease;
    -webkit-user-drag: none;
    &.rotating {
      animation: rotation 20s linear infinite;
      transition: all ease;
    }
  }

  h2 {
    padding: 3rem 1rem 1rem 1rem;
  }

  h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .song-container {
    img {
      width: 60%;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
